var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mx-1 my-3",attrs:{"align":"center"}},[_c('v-btn',{staticClass:"btn",staticStyle:{"border-radius":"0px","border":"solid"},attrs:{"tile":"","color":"primary","dark":"","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إنشاء طلب تصريح أمني")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],staticClass:"mr-1",attrs:{"label":"عنوان المعاملة","hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},model:{value:(_vm.DecsriptionSearch),callback:function ($$v) {_vm.DecsriptionSearch=$$v},expression:"DecsriptionSearch"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-tabs',{attrs:{"height":"65","active-class":"primary--text","current":_vm.$store.state.user.role == 'Security' ? 2 : 1,"slider-size":"7","color":"primary","background-color":"white","slider-color":"primary"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.user.role == 'Benefit'),expression:"$store.state.user.role == 'Benefit'"}],staticClass:"black--text",on:{"click":() => {
              (_vm.currentStep = 1), _vm.getItems();
            }}},[_c('h4',[_vm._v("قيد الإنشاء")])]),_c('v-tab',{staticClass:"black--text",on:{"click":() => {
              (_vm.currentStep = 2), _vm.getItems();
            }}},[_c('h4',[_vm._v("قيد المراجعة")])]),_c('v-tab',{staticClass:"black--text",on:{"click":() => {
              (_vm.currentStep = 3), _vm.getItems();
            }}},[_c('h4',[_vm._v("مكتملة")])])],1)],1)],1),_c('v-data-table',{staticClass:"mytable",attrs:{"items-per-page":_vm.$global.state.filter.pageSize,"items":_vm.items,"loading":_vm.$global.state.loading,"headers":_vm.headers,"hide-default-footer":"","loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no-data'),"id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.created",fn:function({ item }){return [_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(new Date(item.created).toLocaleDateString("sv").split("T")[0])+","),_c('span',[_vm._v(_vm._s(new Date(item.created).toLocaleString().split(",")[1]))])])]}},{key:"item.status",fn:function({}){return [(_vm.step == 1)?_c('v-chip',{attrs:{"color":"indigo","dark":""}},[_vm._v(" قيد الإنشاء ")]):_vm._e(),(_vm.step == 2)?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" قيد المراجعة ")]):_vm._e(),(_vm.step == 3)?_c('v-chip',{attrs:{"color":"teal","dark":""}},[_vm._v(" مكتملة ")]):_vm._e()]}},{key:"item.startAt",fn:function({ item }){return [_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(new Date(item.startAt).toLocaleDateString("sv").split("T")[0][0] == 0 ? " " : new Date(item.startAt).toLocaleDateString("sv").split("T")[0])+" ")])]}},{key:"item.expireAt",fn:function({ item }){return [_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(new Date(item.expireAt).toLocaleDateString("sv").split("T")[0][0] == 0 ? " " : new Date(item.expireAt).toLocaleDateString("sv").split("T")[0])+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"to":'/permitStep/' + item.id + '/' + item.decsription,"icon":""}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" view_list ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('mtabah-altlb')))])])]}}])}),_c('Pagination'),_c('Dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }