<template>
  <div>
    <v-row align="center" class="mx-1 my-3">
      <v-btn
        @click="openAddDialog()"
        tile
        color="primary"
        class="btn"
        style="border-radius: 0px; border: solid"
        dark
        large
      >
        <v-icon>add_circle_outline</v-icon>
        <h3>إنشاء طلب تصريح أمني</h3>
      </v-btn>
      <v-spacer />
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="DecsriptionSearch"
          label="عنوان المعاملة"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-card outlined>
        <v-tabs
          height="65"
          active-class="primary--text"
          v-model="step"
          :current="$store.state.user.role == 'Security' ? 2 : 1"
          slider-size="7"
          color="primary"
          background-color="white"
          slider-color="primary"
        >
          <v-tab v-show="false" />
          <v-tab
            class="black--text"
            v-show="$store.state.user.role == 'Benefit'"
            @click="
              () => {
                (currentStep = 1), getItems();
              }
            "
            ><h4>قيد الإنشاء</h4></v-tab
          >
          <v-tab
            class="black--text"
            @click="
              () => {
                (currentStep = 2), getItems();
              }
            "
            ><h4>قيد المراجعة</h4></v-tab
          >
          <v-tab
            class="black--text"
            @click="
              () => {
                (currentStep = 3), getItems();
              }
            "
            ><h4>مكتملة</h4></v-tab
          >
        </v-tabs>
      </v-card>
    </v-row>

    <v-data-table
      class="mytable"
      :items-per-page="$global.state.filter.pageSize"
      :items="items"
      :loading="$global.state.loading"
      :headers="headers"
      hide-default-footer
      :loading-text="$t('loading')"
      :no-data-text="$t('no-data')"
      :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
    >
      <template v-slot:item.created="{ item }">
        <div class="grey--text">
          {{
            new Date(item.created).toLocaleDateString("sv").split("T")[0]
          }},<span>{{
            new Date(item.created).toLocaleString().split(",")[1]
          }}</span>
        </div>
      </template>

      <template v-slot:item.status="{}">
        <v-chip v-if="step == 1" color="indigo" dark> قيد الإنشاء </v-chip>
        <v-chip v-if="step == 2" color="orange" dark> قيد المراجعة </v-chip>
        <v-chip v-if="step == 3" color="teal" dark> مكتملة </v-chip>
      </template>

      <template v-slot:item.startAt="{ item }">
        <div class="grey--text">
          {{
            new Date(item.startAt).toLocaleDateString("sv").split("T")[0][0] ==
            0
              ? " "
              : new Date(item.startAt).toLocaleDateString("sv").split("T")[0]
          }}
        </div>
      </template>

      <template v-slot:item.expireAt="{ item }">
        <div class="grey--text">
          {{
            new Date(item.expireAt).toLocaleDateString("sv").split("T")[0][0] ==
            0
              ? " "
              : new Date(item.expireAt).toLocaleDateString("sv").split("T")[0]
          }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-tooltip bottom="">
                <template v-slot:activator="{ on }">
                    <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                        <v-icon color="error">
                            delete
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('hthf') }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
                <template v-slot:activator="{ on }">
                    <v-btn @click="openEditDialog(item)" v-on="on" icon>
                        <v-icon color="info">
                            edit
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('edit') }}</span>
            </v-tooltip> -->

        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              :to="'/permitStep/' + item.id + '/' + item.decsription"
              v-on="on"
              icon
            >
              <v-icon color="primary"> view_list </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('mtabah-altlb') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- </v-card> -->
    <Pagination />
    <Dialog />
  </div>
</template>

<script>
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
export default {
  computed: {
    isReport() {
      return this.$route.path.search("Report") > -1;
    },
  },
  components: {
    Dialog: () => import("./dialog"),
  },
  data() {
    return {
      items: [],
      connection: null,
      DecsriptionSearch: "",
      step: this.$store.state.user.role == "Security" ? 2 : 1,
      currentStep: this.$store.state.user.role == "Security" ? 2 : 1,
      headers: [
        {
          text: "تسلسل التخويل",
          value: "sepuence",
        },
        {
          text: this.$t('alwsf'),
          value: "decsription",
        },
        {
          text: "منشئ التخويل",
          value: "creator.fullName",
        },
        {
          text: "الجهة الطالبة للتخويل",
          value: "creator.organization.name",
        },
        {
          text: this.$t("alhalh"),
          value: "status",
        },
        {
          text: "تاريخ و وقت الإنشاء",
          value: "created",
        },
        {
          text: "تاريخ البداية",
          value: "startAt",
        },
        {
          text: "تاريخ النهاية",
          value: "expireAt",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },

  created() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.$config.BASE_URL_PROD}/SignalRHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Error)
      .build();
    this.connection.Credentials = true;
    this.connection
      .start()
      .then(() => {
        console.log("connected");
        // this.ready = true
      })
      .catch((err) => {
        console.error(err.toString());
      });
  },

  mounted() {
    this.connection.on(
      `permit_${this.$store.state.user.user.organization.id}`,
      (send) => {
        // this.connection.on(`group_094dee9d-99f8-4ffe-bbde-95b5e83530c7`, send => {
        try {
          console.log("send org");
          this.getItems();
          this.$eventBus.$emit("refresh-notifications");
          console.log(send);
        } catch (e) {
          console.log("e");
          console.log(e);
        }
      }
    );
    console.log("this.$store.state.user.userGroups");
    console.log(this.$store.state.user);
    for (let i = 0; i < this.$store.state.user.user.userGroups.length; i++) {
      this.connection.on(
        `group_${this.$store.state.user.user.userGroups[i].groupId}`,
        (send) => {
          // this.connection.on(`group_094dee9d-99f8-4ffe-bbde-95b5e83530c7`, send => {
          try {
            console.log("send");
            this.getItems();
            this.$eventBus.$emit("refresh-notifications");
            console.log(send);
          } catch (e) {
            console.log("e");
            console.log(e);
          }
        }
      );
    }

    this.getItems();
    // console.log("this.$store.state.user");
    // console.log(this.$store.state.user);
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },

  methods: {
    async getItems() {
      this.$global.state.loading = true;
      try {
        //${new Date().toLocaleDateString("sv").substr(0,10)}
        let date = new Date();
        let url = `Permit?Step=${this.currentStep}&PageIndex=${
          this.$global.state.filter.pageIndex
        }&PageSize=${this.$global.state.filter.pageSize}&Decsription=${
          this.DecsriptionSearch
        }&ExpireDate=${new Date()
          .toISOString()
          .slice(0, 10)}&IsDelete=false&Sort=desc&OrganizationId=&StatusId=`;
        let GroupIds = [];
        if (this.$store.state.user.user.userGroups.length > 0) {
          await this.$store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }
        for (let j = 0; j < GroupIds.length; j++) {
          url = url + `&GroupIds=${GroupIds[j]}`;
        }
        const res = await this.$http.get(url);
        this.items = res.data.result;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },

    // deleteItem(id, i) {
    //     this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`, 'هل أنت متأكد؟')
    //     this.$eventBus.$once(`delete`, () => {
    //         this.$global.state.regions.splice(i, 1)
    //     })
    // },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
};
</script>
